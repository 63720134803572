import React, { useState } from "react";
import { wpURL } from "../components/URL";
import useFetch from "../hooks/useFetch";
import ReactMarkdown from "react-markdown";
import { Link as ScrollLink } from "react-scroll";
import Loading from "../components/Loading";
import StartUpImage from "../components/StartUpImage";
import { motion } from "framer-motion";
import { saveAs } from "file-saver";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";
import "../css/imageDownload.css";

//Stylesheets new
import "../css/Home.css";

const Fanclub = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [inputs, setInputs] = useState({});
  const { loading, error, data } = useFetch(wpURL + "fanclub?per_page=100");
  if (loading) return <Loading />;
  if (error) return <p>Error :I</p>;

  const fanclub = data[0];

  const handleVerify = () => {
    setCaptcha(true);
    console.log("verified!");
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    setCaptcha(false);
    setRefreshReCaptcha((r) => !r);

    if (captcha) {
      event.preventDefault();
      //alert(inputs);
      if (!inputs.anschrift) {
        inputs.anschrift = "Herr";
      }
      if (!inputs.mitgliedschaft) {
        inputs.mitgliedschaft = "Aktiv";
      }
      let date = new Date();
      date = date.toISOString().split("T")[0];
      console.log(inputs.geburtstag);
      console.log(date);
      await fetch(
        `https://gecefgcxhtkvceusrh3pi5vxbe0ahzff.lambda-url.eu-central-1.on.aws/?Anrede=${inputs.anschrift}&Vorname=${inputs.vorname}&Nachname=${inputs.nachname}&E-Mail=${inputs.email}&Geburtsdatum=${inputs.geburtstag}&Adresse=${inputs.strasse}&PLZ=${inputs.plz}&Ort=${inputs.ort}&Land=${inputs.land}&Telefon%20Privat=${inputs.telefon}&Status=${inputs.mitgliedschaft}&Eintritt=${date}`,
        { method: "GET" }
      );
      setRegistered(true);
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <title>Fanclub</title>
      <StartUpImage image={fanclub.acf.titleimage} />
      <motion.div
        className="startBox"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2 }}
      >
        <h1
          className={`${"startText"} ${"font2"}`}
          style={{ fontWeight: "600" }}
        >
          <ReactMarkdown>{fanclub.acf.title}</ReactMarkdown>
        </h1>
        <h2 className={`${"aboutStart"} ${"aboutText"}`}>
          <div dangerouslySetInnerHTML={{ __html: fanclub.acf.subtitle }}></div>
        </h2>
        <ScrollLink to="target" smooth={true} className={`${"startButton"}`}>
          MEHR ERFAHREN
        </ScrollLink>
      </motion.div>
      <div id="target"></div>
      {registered ? (
        <div className="blogHeading" style={{ marginBottom: "100px" }}>
          <h2 style={{ margin: "auto" }}>Registrierung abgeschickt!</h2>
          <p>
            Herzlich willkommen im Fanclub von Jari Claes! Wir werden uns
            zeitnah mit weiteren Informationen zu Ihrer Mitgliedschaft bei Ihnen
            melden.
          </p>
        </div>
      ) : (
        <div className="blogHeading" style={{ marginBottom: "100px" }}>
          <h2 style={{ margin: "auto" }}>{fanclub.acf.title}</h2>
          <ReactMarkdown>{fanclub.acf.markdownpagedescription}</ReactMarkdown>

          <form className="fanclubRegist" method="post" onSubmit={handleSubmit}>
            <h2>Jetzt registrieren</h2>
            <h3>Persönliche Informationen</h3>
            <div className="fanclubForm">
              <div>
                <label>Anschrift:</label>
                <select
                  name="anschrift"
                  value={inputs.anschrift || ""}
                  defaultValue={"Herr"}
                  required={true}
                  onChange={handleChange}
                >
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                </select>
              </div>
              <div>
                <label>Vorname:</label>
                <input
                  type="text"
                  name="vorname"
                  required={true}
                  value={inputs.vorname || ""}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Nachname:</label>
                <input
                  type="text"
                  name="nachname"
                  required={true}
                  value={inputs.nachname || ""}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>E-Mail:</label>
                <input
                  type="email"
                  name="email"
                  required={true}
                  value={inputs.email || ""}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Geburtsdatum:</label>
                <input
                  type="date"
                  name="geburtstag"
                  required={true}
                  value={inputs.geburtstag || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <h3>Adresse</h3>
            <div className="fanclubForm">
              <div>
                <label>Straße/Hausnummer:</label>
                <input
                  type="text"
                  name="strasse"
                  required={true}
                  value={inputs.strasse || ""}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Postleitzahl:</label>
                <input
                  type="text"
                  name="plz"
                  required={true}
                  value={inputs.plz || ""}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Ort:</label>
                <input
                  type="text"
                  name="ort"
                  required={true}
                  value={inputs.ort || ""}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Land:</label>
                <input
                  type="text"
                  name="land"
                  required={true}
                  value={inputs.land || ""}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Telefonnummer:</label>
                <input
                  type="tel"
                  name="telefon"
                  required={true}
                  value={inputs.telefon || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <h3>Art der Mitgliedschaft</h3>
            <div className="fanclubForm" style={{ marginBottom: "20px" }}>
              <div>
                <label>Mitgliedschaft:</label>
                <select
                  name="mitgliedschaft"
                  value={inputs.mitgliedschaft || ""}
                  defaultValue={"Aktiv"}
                  required={true}
                  onChange={handleChange}
                >
                  <option value="Aktivmitglied">Aktivmitglied</option>
                  <option value="Aktivmitglied Studenten">Student</option>
                  <option value="Goenner">Gönner</option>
                </select>
              </div>
            </div>
            <ReactMarkdown>
              {fanclub.acf.markdownregistdescription}
            </ReactMarkdown>
            <button
              className="registButton"
              style={{
                marginTop: "50px",
              }}
              type="submit"
            >
              Registrierung abschließen!
            </button>
          </form>

          <ReactMarkdown>{fanclub.acf.markdownkontakt}</ReactMarkdown>
        </div>
      )}
      <div>
        <GoogleReCaptchaProvider reCaptchaKey="6Leoy5YpAAAAAPU3Oo7rrJViEPFUfSrQ7inWloO6">
          <GoogleReCaptcha onVerify={handleVerify} />
        </GoogleReCaptchaProvider>
      </div>
    </div>
  );
};

export default Fanclub;
//<div dangerouslySetInnerHTML={{ __html: fanclub.acf.content }}></div>
