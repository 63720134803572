import React from "react";
import { wpURL } from "../components/URL";
import useFetch from "../hooks/useFetch";
import ReactMarkdown from "react-markdown";

//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";
import "../css/Sponsor01Style.css";

//Stylesheets new
import "../css/Home.css";

const MaterialSponsorGrid = () => {
  const { loading, error, data } = useFetch(
    wpURL + "materialsponsoren?per_page=100"
  );
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :I</p>;
  return (
    <div>
      <section
        className="sponsor-type01"
        style={{ paddingBottom: "0vh", marginBottom: "50px" }}
      >
        <div className="sponsor02-container">
          {data.map((sponsor) => (
            <a
              href={sponsor.acf.link}
              target="_blank"
              alt={`Zu: ${sponsor.acf.name}`}
              className={`${"sponsor01-Image"} ${"sponsor02-Image"}`}
              style={{
                backgroundImage: `url("${sponsor.acf.logo}")`,
              }}
            ></a>
          ))}
        </div>
      </section>
    </div>
  );
};

export default MaterialSponsorGrid;
