import React from 'react'

import "../css/Home.css";

const Loading = () => {
  return (
    <div className='loading'>Loading</div>
  )
}

export default Loading