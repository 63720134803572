import React from "react";
import { motion } from "framer-motion";

import "../css/StartIMG01-01.css";

const StartUpImage = (image) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return (
    <>
      {width > 1000 && (
        <div style={{ backgroundColor: "black" }}>
          <motion.div
            className="startupimage"
            style={{
              backgroundPosition: "center",
              backgroundImage: `url("${image.image}")`,
              display: "grid",
            }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 2 }}
          ></motion.div>
        </div>
      )}
      {width <= 1000 && (
        <div style={{ backgroundColor: "black" }}>
          <motion.div
            className="startupimage"
            style={{
              backgroundPosition: "center",
              backgroundImage: `url("${image.image}")`,
            }}
            initial={{ y: 50, opacity: 0, height: "100vh" }}
            animate={{ y: 0, opacity: 1, height: "92vh" }}
            transition={{ duration: 2 }}
          ></motion.div>
        </div>
      )}
    </>
  );
};

export default StartUpImage;
