import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { strapiURL, wpURL } from "../components/URL";
import ReactMarkdown from "react-markdown";
import "../css/Footer01.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [data_social, setData_social] = useState(null);
  const [loading_social, setLoading_social] = useState(true);
  const [error_social, setError_social] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(wpURL + "footer?per_page=100");
        const response_social = await fetch(wpURL + "socialmedia?per_page=100");
        if (!response.ok && !response_social.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();
        const jsonData_social = await response_social.json();
        setData(jsonData);
        setData_social(jsonData_social);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
        setLoading_social(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount
  return (
    <div className="footer">
      <section className="footer01">
        <Link to="/impressum" className="footerElement">
          Impressum
        </Link>
        <Link to="/datenschutz" className="footerElement">
          Datenschutz
        </Link>
      </section>
      <section className="footer01">
        {data &&
          data.map((content) => (
            <div>
              <section
                className="aboutHeading"
                style={{ marginTop: "0px", color: "white" }}
              >
                {content.title.rendered}
              </section>
              <section
                className="aboutText"
                style={{ textAlign: "left", color: "white", marginTop: "30px" }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: content.content.rendered }}
                ></div>
              </section>
            </div>
          ))}
      </section>
      <section className="footer01">
        <section
          className="aboutHeading"
          style={{ marginTop: "0px", color: "white" }}
        >
          Social Media
        </section>
        {data_social &&
          data_social
            .sort((a, b) => b.acf.order - a.acf.order)
            .map((content) => (
              <a
                className="footerRow"
                href={content.acf.link_to_social_media}
                style={{ textDecoration: "None" }}
              >
                <div
                  className="footerIcon"
                  style={{ backgroundImage: `url(${content.acf.logo.url})` }}
                ></div>
                <div
                  className="aboutText"
                  style={{
                    textAlign: "left",
                    color: "white",
                    marginTop: "0px",
                  }}
                >
                  {content.acf.title}
                </div>
              </a>
            ))}
      </section>
    </div>
  );
};

export default Footer;
