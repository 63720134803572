import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

// page & layout imports
import Homepage from "./pages/Home";
import En_Homepage from "./pages/En_Home";
import Erfolge from "./pages/Erfolge";
import Medien from "./pages/Medien";
import Sponsoren from "./pages/Sponsoren";
import SiteHeader from "./components/SiteHeader";
import Footer from "./components/Footer";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import Blog from "./pages/Blog";
import BlogPage from "./pages/BlogPage";
import FanClub from "./pages/Fanclub";
// Strapi API URL
import { strapiURL } from "./components/URL";

// Apollo Client

const client = new ApolloClient({
  uri: `${strapiURL}/graphql`,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <Router>
      <ApolloProvider client={client}>
        <SiteHeader />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/en" element={<En_Homepage />} />
          <Route path="/sponsoren" element={<Sponsoren />} />
          <Route path="/erfolge" element={<Erfolge />} />
          <Route path="/fanclub" element={<FanClub />} />
          <Route path="/medien" element={<Medien />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPage />} />
        </Routes>
        <Footer />
      </ApolloProvider>
    </Router>
  );
}

export default App;
