import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Blog from "../components/BlogGrid";
import Info from "../components/InfoGrid";
import useFetch from "../hooks/useFetch";
import { strapiURL, wpURL } from "../components/URL";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import Loading from "../components/Loading";

//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";

//Stylesheets new
import "../css/Home.css";

const InfoGrid = () => {
  //const { loading, error, data } = useFetch(strapiURL + "/api/home?populate[infobox][populate][0]=titleImage");
  const { loading, error, data } = useFetch(wpURL + "infoblogs?per_page=100");
  if (loading) return <Loading />;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :I</p>;

  return (
    <div style={{ paddingBottom: "50px" }}>
      {data.map((ibox) => (
        <section className="container-type01">
          <div className="innerContainer-type01">
            <div
              className={`${"container-01-Image"}`}
              style={{
                backgroundImage: `url("${ibox.acf.titleimage}")`,
              }}
            ></div>
            <div className="container-01-Text">
              <h2 className="aboutHeading" style={{ marginBottom: "25px" }}>
                {ibox.title.rendered}
              </h2>
              <p className="aboutText">
                <div
                  dangerouslySetInnerHTML={{ __html: ibox.content.rendered }}
                ></div>
              </p>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default InfoGrid;
