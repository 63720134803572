import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

//Stylesheet
import "../css/Menu01.css";

const SiteHeader = () => {
  const [isActive, setActive] = useState(false);

  return (
    <nav className={`${isActive && "topBlack"} ${"topnav"}`}>
      <Link to={"/"} className="logo">
        {/*<!--<h4>The Nav</h4>-->*/}
      </Link>
      <ul className={`${isActive && "nav-active"} ${"nav-links"}`}>
        <li>
          <a>
            <Link to={"/"} onClick={() => setActive(!isActive)}>
              ÜBER MICH
            </Link>
          </a>
        </li>
        <li>
          <a>
            <Link to={"/blog"} onClick={() => setActive(!isActive)}>
              BLOG
            </Link>
          </a>
        </li>
        <li>
          <a>
            <Link to={"/sponsoren"} onClick={() => setActive(!isActive)}>
              SPONSOREN
            </Link>
          </a>
        </li>
        <li>
          <a>
            <Link to={"/erfolge"} onClick={() => setActive(!isActive)}>
              ERFOLGE
            </Link>
          </a>
        </li>
        <li>
          <a>
            <Link to={"/fanclub"} onClick={() => setActive(!isActive)}>
              FANCLUB
            </Link>
          </a>
        </li>
        <li>
          <a>
            <Link to={"/medien"} onClick={() => setActive(!isActive)}>
              MEDIEN
            </Link>
          </a>
        </li>
      </ul>
      <div
        className={`${isActive && "toggle"} ${"burger"}`}
        onClick={() => setActive(!isActive)}
      >
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
};

export default SiteHeader;
