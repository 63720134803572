import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Blog from "../components/BlogGrid";
import InfoGrid from "../components/InfoGrid";
import Bio from "../components/Bio";
import News from "../components/News";
import useFetch from "../hooks/useFetch";
import { en_wpURL } from "../components/URL";
import ReactMarkdown from "react-markdown";
import { Link as ScrollLink } from "react-scroll";
import Loading from "../components/Loading";
import { CSSTransition } from "react-transition-group";
//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";

//Stylesheets new
import "../css/Home.css";

const En_Home = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { loading, error, data } = useFetch(en_wpURL + "home");
  if (loading) return <Loading />;
  if (error) return <p>Error :I</p>;

  //TODO: Redirect to english page console.log(navigator.language);

  const home = data[0];

  home.acf.scrollbutton = home.acf.en_scrollbutton;
  home.acf.aktuellestitle = home.acf.en_aktuellestitle;
  home.acf.aktuellescontent = home.acf.en_aktuellescontent;
  home.acf.blogbutton = home.acf.en_blogbutton;
  home.acf.newstitle = home.acf.en_newstitle;
  home.acf.newscontent = home.acf.en_newscontent;
  home.acf.biotitle = home.acf.en_biotitle;

  return (
    <div className="fadeIn">
      <title>JARI CLAES</title>
      <div
        className="startupimage"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url("${home.acf.titleimage}")`,
        }}
      ></div>
      <div className="startBox">
        <h1
          className={`${"startText"} ${"font2"}`}
          style={{ fontWeight: "600" }}
        >
          <ReactMarkdown>{home.title.rendered}</ReactMarkdown>
        </h1>
        <h2 className={`${"aboutStart"} ${"aboutText"}`}>
          <div
            dangerouslySetInnerHTML={{ __html: home.content.rendered }}
          ></div>
        </h2>
        <ScrollLink to="target" smooth={true} className={`${"startButton"}`}>
          {home.acf.scrollbutton}
        </ScrollLink>
      </div>
      <div id="target"></div>
      <InfoGrid />
      <section
        className="gapimage"
        style={{
          backgroundImage: `url("${home.acf.scrollimage}")`,
        }}
      ></section>
      <div className="blogHeading">
        <h2 style={{ margin: "auto" }}>{home.acf.aktuellestitle}</h2>
        <ReactMarkdown>{home.acf.aktuellescontent}</ReactMarkdown>
      </div>
      <Blog buttonTxt={home.acf.blogbutton} />
      <News content={home.acf} />
      {/*<section
        className="gapimage"
        style={{
          backgroundImage: `url("${strapiURL}${data.data.attributes.fixedImage.data.attributes.url}")`,
        }}
      ></section>*/}
      <Bio content={home.acf} />
    </div>
  );
};

export default En_Home;

{
  /*<div className="startBox">
			<h1 className={`${"startText"} ${"font2"}`}>JARI <span style={{fontWeight: "300"}}>CLAES</span></h1>
			<h2 className={`${"aboutStart"} ${"aboutText"}`}>Jage bis du der Gejagte bist!</h2>
			<a className={`${"startButton"} ${"button"} ${"buttonTXT2"} ${"font2"} ${"center"}`}>MEHR ERFAHREN</a>
</div>*/
}
