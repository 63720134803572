import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { strapiURL, wpURL } from "../components/URL";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ReactMarkdown from "react-markdown";

//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";

const Bio = (content) => {
  //const { loading, error, data } = useFetch(strapiURL + "/api/bio?populate=*");
  const { loading, error, data } = useFetch(
    wpURL + "biocategories?per_page=100"
  );
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :I</p>;

  const acf = content.content;

  let datablogs = data;
  datablogs.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  datablogs.reverse();

  return (
    <section className="InfoBox" style={{ margin: "0px" }}>
      <section className="InfoInnerBox">
        <div
          className="profilepic"
          style={{ backgroundImage: `url("${acf.bioimage}")` }}
        ></div>
        <h1 className="BioHeading">{acf.biotitle}</h1>
        <section
          className="aboutText"
          style={{ textAlign: "left", lineHeight: "30px" }}
        >
          <table className="BioTable">
            {data.map((cat) => (
              <tr>
                <td style={{ fontWeight: "600" }}>{cat.title.rendered}:</td>
                <td>
                  <div
                    dangerouslySetInnerHTML={{ __html: cat.content.rendered }}
                  ></div>
                </td>
              </tr>
            ))}
          </table>
        </section>
      </section>
    </section>
  );
};

export default Bio;
