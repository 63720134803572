import React, { useState, useEffect, useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { strapiURL, wpURL } from "./URL";
import "../css/Home.css";
import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const BlogGrid = ({ buttonTxt }) => {
  //const { loading, error, data } = useFetch(strapiURL+"/api/blogs?populate=*");
  const { loading, error, data } = useFetch(wpURL + "blogs?per_page=100");

  const [swipe, setSwipe] = useState(0);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const datablogs = data;

  function changeSwipe(direction) {
    const newValue = swipe + direction * 550;

    if (newValue < 1 && newValue > -data.length * 550) {
      setSwipe(newValue);
    }
  }

  datablogs.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
  datablogs.reverse();
  function getPreview(content, chars) {
    return content
      .substring(content.indexOf("<p>") + 3, content.indexOf("</p>"))
      .substring(0, chars);
  }
  function shortTitle(content, chars) {
    if (content.length > chars) {
      return content.substring(0, chars) + "...";
    } else {
      return content;
    }
  }

  return (
    <div>
      <div className="blog">
        <div className="blogArrowLeft" onClick={() => changeSwipe(1)}>
          <FaArrowLeft />
        </div>
        <div className="blogBox">
          <div
            className="blogPosts"
            style={{ transform: `translateX(${swipe}px)` }}
          >
            {datablogs.map((blog) => (
              <div className="blogPost">
                <div
                  className="blogIMG"
                  style={{
                    backgroundImage: `linear-gradient(180deg,
                    rgba(0,0,0, 0) 40%,
                    rgba(0,0,0, 1)),url("${blog.acf.titleimage}")`,
                  }}
                ></div>
                <div className="blogText">
                  <h2>{blog.title.rendered}</h2>
                  <p>{getPreview(blog.content.rendered, 250)}...</p>
                  <Link className="toBlogButton" to={`/blog/${blog.id}`}>
                    Mehr erfahren
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="mobileBlog">
            <div
              className="blogIMG"
              style={{
                backgroundImage: `linear-gradient(180deg,
                        rgba(0,0,0, 0) 40%,
                        rgba(0,0,0, 1)),url("${datablogs[0].acf.titleimage}")`,
              }}
            ></div>
            <div className="blogText">
              <h2>{datablogs[0].title.rendered}</h2>
              <p>{getPreview(datablogs[0].content.rendered, 200)}...</p>
              <Link className="toBlogButton" to={`/blog/${datablogs[0].id}`}>
                Mehr erfahren
              </Link>
            </div>
          </div>
        </div>
        <div className="blogArrowRight" onClick={() => changeSwipe(-1)}>
          <FaArrowRight />
        </div>
      </div>
      <Link to={"/blog"} style={{ textDecoration: "none" }}>
        <div className="blogButton">{buttonTxt}</div>
      </Link>
    </div>
  );
};

export default BlogGrid;
