import React from "react";
import useFetch from "../hooks/useFetch";
import { wpURL } from "../components/URL";
import "../css/Blog.css";
import Loading from "../components/Loading";

const Datenschutz = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { loading, error, data } = useFetch(wpURL + "datenschutz");
  if (loading) return <Loading />;
  if (error) return <p>Error :I</p>;

  const datenschutz = data[0];

  return (
    <div
      style={{
        paddingTop: "100px",
        paddingBottom: "100px",
        backgroundColor: "white",
      }}
    >
      <title>Datenschutz</title>
      <div className="blogHeading">
        <h2 style={{ margin: "auto" }}>{datenschutz.title.rendered}</h2>
        <div
          className="blogBody"
          dangerouslySetInnerHTML={{ __html: datenschutz.content.rendered }}
        ></div>
      </div>
    </div>
  );
};

export default Datenschutz;
