import React from "react";
import { wpURL } from "../components/URL";
import useFetch from "../hooks/useFetch";
import Loading from "../components/Loading";

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";
import "../css/Sponsor01Style.css";
import "../css/InfoBox01.css";
import "../css/Timestamp01.css";
import "../css/Home.css";

const ErfolgeList = () => {
  const { loading, error, data } = useFetch(wpURL + "erfolgelist?per_page=100");
  if (loading) return <Loading />;
  if (error) return <p>Error :I</p>;

  const erfolgelist = data;

  erfolgelist.sort((a, b) => {
    return new Date(a.slug) - new Date(b.slug);
  });

  erfolgelist.reverse();

  return (
    <div>
      {erfolgelist.map((erfolge) => (
        <section className="timestamp01">
          <section className="time-left">
            <h1>{erfolge.title.rendered}</h1>
            <section className="time-flow"></section>
          </section>

          <section className="aboutText">
            <div
              dangerouslySetInnerHTML={{ __html: erfolge.content.rendered }}
            ></div>
          </section>
        </section>
      ))}
    </div>
  );
};

export default ErfolgeList;
