import React from "react";
import { wpURL } from "../components/URL";
import useFetch from "../hooks/useFetch";
import ReactMarkdown from "react-markdown";
import { Link as ScrollLink } from "react-scroll";
import Loading from "../components/Loading";
import ErfolgeList from "../components/ErfolgeList";
import StartUpImage from "../components/StartUpImage";
import { motion } from "framer-motion";
//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";
import "../css/Sponsor01Style.css";
import "../css/InfoBox01.css";
import "../css/Timestamp01.css";

//Stylesheets new
import "../css/Home.css";

const Erfolge = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { loading, error, data } = useFetch(wpURL + "erfolge");
  if (loading) return <Loading />;
  if (error) return <p>Error :I</p>;

  const erfolge = data[0];

  return (
    <div style={{ backgroundColor: "white" }}>
      <title>Erfolge</title>
      <StartUpImage image={erfolge.acf.titleimage} />
      <motion.div
        className="startBox"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2 }}
      >
        <h1
          className={`${"startText"} ${"font2"}`}
          style={{ fontWeight: "600" }}
        >
          <ReactMarkdown>{erfolge.title.rendered}</ReactMarkdown>
        </h1>
        <h2 className={`${"aboutStart"} ${"aboutText"}`}>
          <div
            dangerouslySetInnerHTML={{ __html: erfolge.content.rendered }}
          ></div>
        </h2>
        <ScrollLink to="target" smooth={true} className={`${"startButton"}`}>
          MEHR ERFAHREN
        </ScrollLink>
      </motion.div>
      <div id="target"></div>
      <div className="blogHeading" style={{ marginBottom: "100px" }}>
        <h2 style={{ margin: "auto" }}>{erfolge.acf.title}</h2>
        <ReactMarkdown>{erfolge.acf.markdownpagedescription}</ReactMarkdown>
      </div>
      <ErfolgeList />
      <div className="InfoBox" style={{ marginBottom: "0" }}>
        <div className="blogHeading" style={{ marginBottom: "100px" }}>
          <h2 style={{ margin: "auto" }}>{erfolge.acf.pressetitle}</h2>
          <p style={{ textAlign: "center" }}>
            <ReactMarkdown>{erfolge.acf.presselinks}</ReactMarkdown>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Erfolge;
