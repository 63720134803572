import React from "react";
import { wpURL } from "../components/URL";
import useFetch from "../hooks/useFetch";
import ReactMarkdown from "react-markdown";
import Haupt from "../components/HauptSponsorGrid";
import Material from "../components/MaterialSponsorGrid";
import Goenner from "../components/GoennerGrid";
import { Link as ScrollLink } from "react-scroll";
import Loading from "../components/Loading";
import StartUpImage from "../components/StartUpImage";
import { motion } from "framer-motion";
//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";
import "../css/Sponsor01Style.css";

//Stylesheets new
import "../css/Home.css";

const Sponsoren = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { loading, error, data } = useFetch(wpURL + "sponsoren?per_page=100");
  if (loading) return <Loading />;
  if (error) return <p>Error :I</p>;

  const sponsorenSite = data[0];

  return (
    <div>
      <title>Sponsoren</title>
      <StartUpImage image={sponsorenSite.acf.titleimage} />
      <motion.div
        className="startBox"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2 }}
      >
        <h1
          className={`${"startText"} ${"font2"}`}
          style={{ fontWeight: "600" }}
        >
          <ReactMarkdown>{sponsorenSite.title.rendered}</ReactMarkdown>
        </h1>
        <h2 className={`${"aboutStart"} ${"aboutText"}`}>
          <div
            dangerouslySetInnerHTML={{ __html: sponsorenSite.content.rendered }}
          ></div>
        </h2>
        <ScrollLink to="target" smooth={true} className={`${"startButton"}`}>
          MEHR ERFAHREN
        </ScrollLink>
      </motion.div>
      <div id="target"></div>
      <div className="blogHeading" style={{ marginBottom: "100px" }}>
        <h2 style={{ margin: "auto" }}>{sponsorenSite.acf.title}</h2>
        <ReactMarkdown>
          {sponsorenSite.acf.markdownpagedescription}
        </ReactMarkdown>
      </div>
      <Haupt />
      <h2 className={`${"sponsorheads"}`} style={{ color: "black" }}>
        MATERIAL SPONSOREN
      </h2>
      <Material />
      <h2 className={`${"sponsorheads"}`} style={{ color: "black" }}>
        GÖNNER
      </h2>
      <Goenner />
    </div>
  );
};

export default Sponsoren;
