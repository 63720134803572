import React from "react";
import { wpURL } from "../components/URL";
import useFetch from "../hooks/useFetch";
import ReactMarkdown from "react-markdown";

//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";
import "../css/Sponsor01Style.css";

//Stylesheets new
import "../css/Home.css";

const HauptSponsorGrid = () => {
  const { loading, error, data } = useFetch(
    wpURL + "hauptsponsoren?per_page=100"
  );
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :I</p>;
  return (
    <div>
      {data.map((sponsor) => (
        <section className="sponsor-type01">
          <a
            className="sponsor01-container"
            target="_blank"
            href={sponsor.acf.link}
            alt={`Zu: ${sponsor.acf.name}`}
          >
            <div
              className="sponsor01-Image"
              style={{
                backgroundImage: `url("${sponsor.acf.logo}")`,
              }}
            ></div>
            <div className="sponsor01-Text">
              <h2 className="aboutHeading" style={{ marginBottom: "20px" }}>
                {sponsor.acf.name}
              </h2>
              <section className="aboutText">{sponsor.acf.description}</section>
            </div>
          </a>
        </section>
      ))}
    </div>
  );
};

export default HauptSponsorGrid;
