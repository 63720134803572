import React from "react";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { wpURL } from "../components/URL";
import { FaArrowLeft, FaArrowRight, FaTimes } from "react-icons/fa";
import Loading from "../components/Loading";
import "../css/Home.css";
import "../css/Blog.css";
import { Parallax } from "react-parallax";
import { motion } from "framer-motion";

const BlogPage = () => {
  const [active, setActive] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [imgId, setImgId] = React.useState(0);

  const { id } = useParams();
  //const { loading, error, data } = useFetch(strapiURL+`/api/blogs/${id}?populate=*`);
  const { loading, error, data } = useFetch(wpURL + `blogs/${id}`);

  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  React.useEffect(() => {
    if (!loaded) {
      window.scrollTo(0, 0);
      setLoaded(true);
    }
    document.addEventListener("keydown", keyPressed);
    return () => {
      document.removeEventListener("keydown", keyPressed);
    };
  }, [keyPressed]);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;

  let imgArr = [];

  function getIMG(string) {
    let img = "hello";
    if (string.indexOf("<img") !== -1) {
      string = string.substring(string.indexOf("<img"));
      img = string.substring(string.indexOf('src="') + 5, string.indexOf(">"));
      img = img.substring(0, img.indexOf('"'));
      imgArr.push(img);
      console.log(imgArr);
      string = string.substring(string.indexOf(">") + 1);
      getIMG(string);
    }
  }
  function replaceIMG(string) {
    if (string.indexOf("<img") === -1) {
      return string;
    }
    return string.substring(0, string.indexOf("<img"));
  }

  function changeImgId(change) {
    const newId = imgId + change;
    const imageLength = imgArr.length;
    if (newId >= 0 && newId < imageLength) {
      setImgId(newId);
    } else {
      if (newId <= 0) {
        setImgId(imageLength - 1);
      }
      if (newId >= imageLength) {
        setImgId(0);
      }
    }
  }

  function changeActive(img) {
    setImgId(img);
    setActive(!active);
  }
  function keyPressed(e) {
    console.log("You pressed a key.");
    if (active) {
      const key = e.key;

      if (key === "Escape") {
        setActive(!active);
      } else if (key === "ArrowLeft") {
        changeImgId(-1);
      } else if (key === "ArrowRight") {
        changeImgId(1);
      }
    }
  }

  getIMG(data.content.rendered);

  return (
    <div style={{ backgroundColor: "white" }}>
      <title>{data.title.rendered}</title>
      {imgArr.length > 0 && active && (
        <div className="imageView">
          <div
            className="currentImg"
            style={{ backgroundImage: `url(${imgArr[imgId]})` }}
          ></div>
          <div className="arrows">
            <div className="arrow" onClick={() => changeImgId(-1)}>
              <FaArrowLeft />
            </div>
            <div
              className="arrow"
              style={{ color: "red" }}
              onClick={() => changeActive()}
            >
              <FaTimes />
            </div>
            <div className="arrow" onClick={() => changeImgId(1)}>
              <FaArrowRight />
            </div>
          </div>
        </div>
      )}
      {width > 1000 && (
        <div style={{ backgroundColor: "white" }}>
          <motion.div
            initial={{ y: 0, opacity: 0, height: "100vh" }}
            animate={{ y: 0, opacity: 1, height: "80vh" }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            <Parallax
              className="topImg"
              bgImage={data.acf.titleimage}
              strength={250}
              style={{ height: "100%" }}
            ></Parallax>
          </motion.div>
        </div>
      )}
      {width <= 1000 && (
        <div
          className="topImg"
          style={{ backgroundImage: `url(${data.acf.titleimage})` }}
        ></div>
      )}
      <div className="blogHeading" style={{ marginBottom: "100px" }}>
        <h2 style={{ margin: "auto", textAlign: "left" }}>
          {data.title.rendered}
        </h2>
        <div
          className="blogBody"
          dangerouslySetInnerHTML={{
            __html: replaceIMG(data.content.rendered),
          }}
        ></div>
      </div>
      {data.acf.videolink !== "" && (
        <div className="videoContainer">
          <iframe
            className="video"
            src={data.acf.videolink}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </div>
      )}
      {imgArr.length > 0 && (
        <div className="gallery">
          {imgArr.map((media, index) => (
            <div
              key={index}
              className="galleryImg"
              onClick={() => changeActive(index)}
              style={{ backgroundImage: `url(${media})` }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlogPage;
